import {Component, input} from '@angular/core';
import {SkeletonModule} from "primeng/skeleton";
import {SkeletonConfigsInterface} from "../../interfaces/skeleton/skeletonConfigsInterface";
import {NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'calimatic-skeleton',
  standalone: true,
  imports: [
    SkeletonModule,
    NgTemplateOutlet
  ],
  templateUrl: './skeleton.component.html',
  styleUrl: './skeleton.component.scss'
})
export class SkeletonComponent {
  configs = input<SkeletonConfigsInterface>();
}
